import React from 'react';

const LogoSyndemosWithoutText: React.FC<React.SVGProps<SVGElement>> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.5 53">
        <g>
          <path
            fill="#b5007b"
            d="m36.3 20.9 10.3 14.9 10.3-14.9h-4.5c1.2-5.6 6.2-9.9 12.1-9.9h24.9c7.8 0 14.2 6.4 14.2 14.2s-6.4 14.3-14.3 14.3H78.6c-1.4 4.1-3.8 7.9-6.7 11h17.4c13.9 0 25.3-11.4 25.3-25.3S103.3 0 89.4 0H64.5c-12.1 0-22 9.2-23.2 20.9h-4.9Z"
          ></path>
          <path
            fill="#042851"
            d="M78.2 32.1 67.9 17.2 57.6 32.1h4.5C60.9 37.8 55.9 42 50 42H25.1c-7.8 0-14.2-6.4-14.2-14.2s6.4-14.3 14.3-14.3h10.7c1.5-4.1 3.8-7.9 6.7-11H25.2C11.3 2.6 0 13.9 0 27.9s11.3 25.2 25.2 25.2h24.9c12.1 0 22-9.2 23.2-20.9h4.9Z"
          ></path>
        </g>
      </svg>
    );
}

export default LogoSyndemosWithoutText;
