import { HTMLAttributes } from "react";
import { useMediaQuery } from "../../../../utilities/hooks/useMediaQuery";
import BtnContainerDesktop from "./BtnContainerDesktop";
import BtnContainerMobile from "./BtnContainerMobile";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: any[] | any;
  position?: "left" | "center" | "right" | "auto";
  espacement?: number;
  forceChildrenLength?: number;
  mediaQuery?: number;
}

export function BtnContainer({ children, position = "auto", espacement = 7, className = "mt-1", forceChildrenLength, mediaQuery = 500, ...props }: Props) {
  const query = useMediaQuery(mediaQuery);

  const newChildren = Array.isArray(children) ? children.filter((element) => element) : children;

  return (
    <>
      {query ? (
        <BtnContainerMobile position={position} espacement={espacement} className={className} {...props}>
          {newChildren}
        </BtnContainerMobile>
      ) : (
        <BtnContainerDesktop position={position} className={className} espacement={espacement} forceChildrenLength={forceChildrenLength} {...props}>
          {newChildren}
        </BtnContainerDesktop>
      )}
    </>
  );
}
