type Props = {
  size?: number;
  color?: string;
};

export default function PennyLane({ size = 40, color }: Props) {
  const st0 = {
    fill: color ?? "#2e4764",
  };
  const st2 = {
    fill: color ?? "#02f772",
  };

  const st1 = {
    fill: "inherit",
  };

  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 2267.7 2267.7"
      width={size + "px"}
      style={st0}
    >
      <g id="Calque_1">
        <path
          style={st1}
          d="M2266.2,1138.8c-5.1,632.2-514,1133.7-1145.3,1128.8C492.8,2262.9-10.4,1749.4.2,1115.1,10.5,491.1,521.1-2.8,1152.7,3.4c610.9,6,1118.5,522.4,1113.5,1135.4ZM2030.8,1135.5c-.3-497.6-403.9-898.1-904.7-897.6-490.4.5-893.2,406.9-892.6,900.7.6,494,404.5,895.3,900.7,894.9,495.9-.4,896.8-402,896.6-898Z"
        />
        <path
          style={st1}
          d="M1136.5,760.7c149.1-95.7,384.6-84.1,534.8,76.9,158.3,169.7,160,418.2,7.4,592.2-134.3,153-385.8,191.8-542.3,83.7,286.9-180.8,280-576.8.2-752.8Z"
        />
        <path
          style={st2}
          d="M1126.2,760.5c-281.2,176.9-279.2,579.9,0,753.9-142.5,94.5-387.2,85.3-541.1-87.3-147.5-165.3-144.7-422.7,2.5-582.9,159.2-173.3,406.2-174.9,538.6-83.6Z"
        />
      </g>
    </svg>
  );
}
