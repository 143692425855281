"use client";

import classNames from "classnames";
import { HTMLAttributes, useState } from "react";
import { Tooltip } from "ui/components/infos";
import { CircleLoader } from "ui/components/loaders";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";

export type ClassnameTypeType =
  | "primary"
  | "primary-light"
  | "white"
  | "transparent"
  | "dark"
  | "error"
  | "red"
  | "error-light"
  | "red-light"
  | "secondary"
  | "secondary-light"
  | "gray"
  | "gray-light"
  | "warning"
  | "yellow"
  | "warning-light"
  | "yellow-light"
  | "success"
  | "green"
  | "success-light"
  | "green-light"
  | "info"
  | "blue"
  | "info-light"
  | "blue-light"
  | "empty"
  | "darken";

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: any | any[];
  cb?: (event: React.MouseEvent<HTMLButtonElement>) => void | { payload: any; type: `${string}/${string}`; } | Promise<any>;
  disabled?: boolean;
  type?: ClassnameTypeType;
  id?: string;
  className?: string;
  form?: "square" | "circle";
  size?: "big" | "small" | "medium";
  iconSize?: number;
  backgroundColor?: CssVariablesType;
  iconColor?: CssVariablesType;
  icon?: IconList | JSX.Element;
  isLoading?: boolean;
  classNameIcon?: string;
  borderColor?: CssVariablesType;
  textColor?: CssVariablesType;
  tooltip?: string;
}

function Btn({
  children = "Button",
  cb,
  isLoading = false,
  disabled = false,
  type = "primary",
  id,
  className,
  size = "medium",
  form = "square",
  iconSize = 20,
  backgroundColor,
  iconColor,
  icon,
  classNameIcon = "",
  borderColor,
  textColor,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      type="button"
      className={classNames("button", type, className, size, form, {
        clickable: cb && isLoading == false && disabled === false,
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        cb && cb(e);
      }}
      disabled={disabled || !cb || isLoading}
      id={id ? id : undefined}
      style={{
        backgroundColor: backgroundColor && `var(--color-${backgroundColor}`,
        borderColor: borderColor && `var(--color-${borderColor}`,
        color: textColor && `var(--color-${textColor}`,
        ...props.style,
      }}
    >
      {icon !== "next" && isLoading && <CircleLoader strokeWidth={7} width={iconSize + "px"} color={iconColor && `var(--color-${iconColor})`} />}

      {icon && typeof icon === "string" && icon !== "next" && isLoading == false && <IconButtonIcons color={iconColor} icon={icon} size={iconSize} className={classNameIcon} />}

      {icon && typeof icon !== "string" && isLoading == false && icon}

      {children}

      {icon && icon === "next" && isLoading == false && <IconButtonIcons color={iconColor} icon={icon} size={iconSize} className={classNameIcon} />}
      {icon && icon === "next" && isLoading && <CircleLoader strokeWidth={7} width={iconSize + "px"} color={iconColor && `var(--color-${iconColor})`} />}
    </button>
  );
};

export function Button({ tooltip, ...props }: ButtonProps) {
  const [isLoading, setIsLoading] = useState(props?.isLoading ?? false);

  async function cb(e: React.MouseEvent<HTMLButtonElement>) {
    setIsLoading(true);
    props?.cb && (await props?.cb(e));
    setIsLoading(false);
  }

  return (
    <>
      {tooltip ? (
        <Tooltip className={props.className} legend={tooltip}>
          <Btn {...props} className="m-0" isLoading={props?.isLoading ?? isLoading} cb={props?.cb && cb}>
            {props.children}
          </Btn>
        </Tooltip>
      ) : (
        <Btn {...props} isLoading={props?.isLoading ?? isLoading} cb={props?.cb && cb}>
          {props.children}
        </Btn>
      )}
    </>
  );
};