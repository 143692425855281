import { Grid } from "../../../components/blocs";
import UserTeamIconItem from "./UserTeamIconItem";

type Props = {
  size?: number;
  color?: string;
  setter?: (arg: string) => void;
  selected?: string;
};

export const userListIcons = ["dollar", "bug", "graduate", "admins", "users"];

const UserTeamIconList = ({ size = 30, color, setter, selected }: Props) => {
  return (
    <Grid template="repeat(auto-fit, 55px)" className="tile gray-light thin" mediaQuery={0}>
      {userListIcons?.map((icon, key) => {
        return <UserTeamIconItem value={icon} key={key} setter={setter} size={size} color={color} selected={selected} />;
      })}
    </Grid>
  );
};

export default UserTeamIconList;
