import { AccountingFirmConnectorTypeType } from "../../../../utilities/redux/types";
import { BsFileArrowDownFill } from "../../../react-icons/bs";
import { GrConnect } from "../../../react-icons/gr";
import { Tooltip } from "../../infos";
import Dext from "../svg/Dext";
import MyUnisoft from "../svg/MyUnisoft";
import PennyLane from "../svg/PennyLane";
import Acd from "./../svg/Acd";
import Cegid from "./../svg/Cegid";
import Sage from "./../svg/Sage";
import Tiime from "./../svg/Tiime";
import Welyb from "./../svg/Welyb";

type Props = {
  value: AccountingFirmConnectorTypeType["value"];
  size?: number;
  color?: string;
};

const Icon = ({ value, size = 40, color }: Props) => {
  if (value == "fec") return <BsFileArrowDownFill className={"icon"} size={size} color={color ?? "var(--color-main)"} />;
  if (value == "quadra" || value == "loop") return <Cegid size={size} color={color} />;
  if (value == "acd") return <Acd size={size} color={color} />;
  if (value == "tiime") return <Tiime size={size} color={color} />;
  if (value == "myunisoft") return <MyUnisoft size={size} color={color} />;
  if (value == "pennylane") return <PennyLane size={size} color={color} />;
  if (value == "dext") return <Dext size={size} color={color} />;
  if (value == "welyb") return <Welyb size={size} color={color} />;
  if (value == "sage") return <Sage size={size} color={color} />;
  return <GrConnect className={"icon"} size={size} color={color ?? "var(--color-main)"} />;
};

export function AccountingFirmConnectorIcon({
  item,
  size = 40,
  tooltip = false,
  color,
}: {
  item?: AccountingFirmConnectorTypeType;
  size?: number;
  className?: string;
  color?: string;
  tooltip?: boolean;
}) {
  return (
    <Tooltip legend={tooltip ? (item?.name ?? item?.value ?? "") : ""} position="bottom">
      {item?.value && <Icon value={item?.value} size={size} color={color} />}
    </Tooltip>
  );
}
