import { RoleTypeType } from "../redux/types";

export type RoleType = { readonly value: RoleTypeType; readonly legend: string };

export const SERVER_URL = process.env.NEXT_PUBLIC_NODE_ENV == "production" ? "https://syndemos.fr/api/" : "http://localhost:8000/api/";

export const EXPERT_URL = process.env.NEXT_PUBLIC_NODE_ENV == "production" ? "https://expert.syndemos.fr/" : "http://localhost:3000/";
export const COMPANY_URL = process.env.NEXT_PUBLIC_NODE_ENV == "production" ? "https://entreprise.syndemos.fr/" : "http://localhost:3001/";
export const ADMIN_URL = process.env.NEXT_PUBLIC_NODE_ENV == "production" ? "https://admin.syndemos.fr/" : "http://localhost:3002/";
export const WEB_URL = process.env.NEXT_PUBLIC_NODE_ENV == "production" ? "https://syndemos.fr/" : "http://localhost:3003/";

export const FILES = {
  fec: { size: 300, type: "txt" },
};

// All roles without duplication
export const ROLES = [
  { value: "ROLE_USER", legend: "Utilisateur" },
  { value: "ROLE_EXPERT", legend: "Expert utilisateur" },
  { value: "ROLE_EXPERT_OWNER", legend: "Expert administrateur" },
  { value: "ROLE_COMPANY", legend: "Entreprise" },
  { value: "ROLE_ADMIN", legend: " Administrateur" },
  { value: "ROLE_SUPER_ADMIN", legend: " Super Administrateur" },
  { value: "ROLE_COMMERCIAL", legend: "Commercial" },
  { value: "ROLE_SUPPORT", legend: "Support" },
] as const;

export const ROLES_EXPERT = ROLES.filter((role) => role.value.includes("USER") || role.value.includes("EXPERT"));

export const ROLES_COMPANY = ROLES.filter((role) => role.value.includes("USER") || role.value.includes("COMPANY"));

export const ROLES_ADMIN = ROLES.filter((role) => role.value.includes("USER") || role.value.includes("ADMIN") || role.value.includes("COMMERCIAL") || role.value.includes("SUPPORT"));

export const CURRENT_VERSION = "1.2.2";

export const currencies = [
  { value: "EUR", legend: "EUR - Euros" },
  { value: "USD", legend: "USD - Dollar" },
  { value: "CHF", legend: "CHF - Franc suisse" },
  { value: "XPF", legend: "XPF - Franc pacificique" },
];
