import classNames from "classnames";
import { CategoryIcon } from "..";
import { CategoryIconType } from "../../../../utilities/redux/types";

interface Props {
  data: CategoryIconType | null;
  color?: string;
  setter: (param: CategoryIconType) => void;
}

export function CategoryIconChoicer({ data, setter, color = "var(--color-gray16)" }: Props) {
  enum CategoryIconType {
    FaMoneyBillWaveAlt = "FaMoneyBillWaveAlt",
    FaUserFriends = "FaUserFriends",
    FaTruck = "FaTruck",
    FaBalanceScale = "FaBalanceScale",
    FaClock = "FaClock",
    IoReceipt = "IoReceipt",
    FaBoxOpen = "FaBoxOpen",
    TbMoneybag = "TbMoneybag",
    FaAddressBook = "FaAddressBook",
    FaBuildingColumns = "FaBuildingColumns",
    FaEarthEurope = "FaEarthEurope",
    FaArchive = "FaArchive",
    FaBed = "FaBed",
    FaBroom = "FaBroom",
  }

  const iconList: CategoryIconType[] = Object.values(CategoryIconType);

  function onClick(icon: CategoryIconType) {
    setter(icon);
  }

  return (
    <div className="category-choicer">
      {iconList.map((icon) => (
        <CategoryIcon
          key={"icon_" + icon}
          className={classNames("icon clickable", icon === data && "selected")}
          iconName={icon as CategoryIconType} // Cast explicite
          size={18}
          onClick={() => onClick(icon as CategoryIconType)}
          color={color}
        />
      ))}
    </div>
  );
}
