"use client";

import classNames from "classnames";
import { useState } from "react";

import { apiURL } from "../../../../utilities/config/server";
import { Modal } from "../../modals";
import { Button, ClassnameTypeType } from "../button/Button";
import { IconButton } from "../iconButton/IconButton";

type Props = {
  url: string;
  size?: number;
  type?: "iconButton" | "button";
  legend?: string;
  className?: string;
  color?: ClassnameTypeType;
};

export function PreviewPDFButton({ url, size = 15, type = "iconButton", legend = "Prévisualiser", className, color = "transparent" }: Props) {

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {type === "iconButton" && (
        <IconButton
          icon="eye"
          tooltip={legend}
          cb={(e) => {
            e.stopPropagation();
            setShowModal(true);
          }}
          size={size}
          className={className}
          type={color}
          form="circle"
        />
      )}
      {type === "button" && (
        <Button
          type={color}
          icon="eye"
          size="small"
          form="circle"
          iconSize={size}
          cb={(e) => {
            e.stopPropagation();
            setShowModal(true);
          }}
          className={classNames(className, "mb-0 mt-0 w-100")}
        >
          {legend}
        </Button>
      )}

      <Modal showModal={showModal} setShowModal={setShowModal} bodyStyle={{ height: "100%" }}>
        <iframe src={`${apiURL}${url}`} height="99%" width="100%" />
      </Modal>
    </>
  );
}
