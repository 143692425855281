import classNames from "classnames";
import { HTMLAttributes } from "react";
import { QuestionExchangeTypeType } from "../../../../utilities/redux/types";
import { FaArrowRight } from "../../../react-icons/fa";
import { Grid } from "../../blocs";
import { Tooltip } from "../../infos";
import { IconButtonIcons } from "../iconButtonIcons/IconButtonIcons";

interface Props extends HTMLAttributes<HTMLDivElement> {
  type: QuestionExchangeTypeType;
  legend?: boolean;
  width?: number;
  iconSize?: number;
  className?: string;
};

export function QuestionExchangeIcon({ type, legend = false, width = 400, iconSize = 25, className, ...props }: Props) {

  return (
    <Tooltip
      {...props}
      legend={(legend && type?.title) ? type?.title : ""} position="bottom"
      className={classNames("question-exchange-icon br-10", className)}
      style={{
        maxWidth: width + "px" || "",
        padding: `${(iconSize * 3) / 4}px`,
        ...props?.style
      }}
    >
      {
        type?.action === "expert_sendQuestion_withTextFile"
          ? <IconButtonIcons icon={type?.action} />
          : <Grid mediaQuery={0} template="1fr 1fr 1fr" alignItems="center" space={5} className="m-auto">
            {type?.action === "expert_sendQuestion" && (
              <>
                <IconButtonIcons icon={"expert_sendQuestion"} size={iconSize} color={"active"} />
                <FaArrowRight size={iconSize * 0.5} color={"var(--color-text)"} />
                <IconButtonIcons icon={"company_sendBackQuestion"} size={iconSize} color={"secondary"} />
              </>
            )}

            {type?.action === "company_sendBackQuestion" && (
              <>
                <IconButtonIcons icon={"company_sendBackQuestion"} size={iconSize} color={"secondary"} />
                <FaArrowRight size={iconSize * 0.5} color={"var(--color-text)"} />
                <IconButtonIcons icon={"expert_sendQuestion"} size={iconSize} color={"active"} />
              </>
            )}

            {type?.action === "company_questionCompletedRecap" && (
              <>
                <IconButtonIcons icon={"company_questionCompletedRecap"} size={iconSize} />
                <FaArrowRight size={iconSize * 0.5} color={"var(--color-text)"} />
                <IconButtonIcons icon={"expert_sendQuestion"} size={iconSize} color={"active"} />
              </>
            )}

            {type?.action === "expert_questionRecap" && (
              <>
                <IconButtonIcons icon={"expert_questionRecap"} size={iconSize} />
                <FaArrowRight size={iconSize * 0.5} color={"var(--color-text)"} />
                <IconButtonIcons icon={"company_sendBackQuestion"} size={iconSize} color={"secondary"} />
              </>
            )}
          </Grid>
      }

    </Tooltip>
  );
}
