import { ReportTypeIcon } from "..";
import { AiFillTag } from "../../../react-icons/ai";
import { BsBell, BsFileArrowDownFill, BsFillBuildingsFill, BsPlugFill } from "../../../react-icons/bs";
import { FaFileContract, FaFileInvoiceDollar, FaMoneyBillAlt, FaPlug, FaUserFriends } from "../../../react-icons/fa";
import { FaMoneyBillTransfer } from "../../../react-icons/fa6";
import { GrUpdate } from "../../../react-icons/gr";
import { HiBuildingOffice, HiIdentification } from "../../../react-icons/hi2";
import { IoIosSend } from "../../../react-icons/io";
import { IoMail, IoSettingsSharp } from "../../../react-icons/io5";
import { LuAlarmClock } from "../../../react-icons/lu";
import { MdAddCircle, MdOutlinePassword, MdOutlinePayment, MdQuestionMark } from "../../../react-icons/md";
import { PiTestTubeFill } from "../../../react-icons/pi";
import { RiUserSettingsLine } from "../../../react-icons/ri";
import { SiAdblock } from "../../../react-icons/si";
import { TbDiscount2, TbPower } from "../../../react-icons/tb";
import { VscSettings } from "../../../react-icons/vsc";
import Acd from "../svg/Acd";
import Cegid from "../svg/Cegid";
import Tiime from "../svg/Tiime";

type Type =
  | "demo"
  | "blocked_accountingFirm"
  | "password"
  | "connector"
  | "tarification"
  | "payment_process"
  | "discount"
  | "contract"
  | "mail"
  | "connexion"
  | "user_preference"
  | "user_notification"
  | "preference"
  | "users"
  | "company_create"
  | "company_informations"
  | "company_employees"
  | "categories"
  | "company"
  | "accountingFirm_informations"
  | "accountingFirm_employees"
  | "suggest"
  | "accountingFirm"
  | "importation"
  | "cycle"
  | "send"
  | "relance"
  | "PES"
  | "fec"
  | "quadra"
  | "tiime"
  | "acd"
  | "connectors"
  | "technique"
  | "tarification"
  | "payment"
  | "credit"
  | "commercial";

type Props = {
  type: Type;
  size?: number;
  color?: string;
  typeSelected?: "technical" | "commercial" | "suggestion" | "message";
};

export function ReportTopicIcon({ type, size = 25, color = "var(--color-text)", typeSelected = null }: Props) {
  return (
    <>
      {/* Default value */}
      {type == null && <ReportTypeIcon type={typeSelected} />}
      {/* Other values */}
      {type === "demo" && <PiTestTubeFill className="icon" size={size} color={color} />}
      {type === "blocked_accountingFirm" && <SiAdblock className="icon" size={size} color={color} />}
      {type === "password" && <MdOutlinePassword className="icon" size={size} color={color} />}
      {type === "connector" && <FaPlug className="icon" size={size} color={color} />}
      {type === "payment_process" && <MdOutlinePayment className="icon" size={size} color={color} />}
      {type === "discount" && <TbDiscount2 className="icon" size={size} color={color} />}
      {type === "contract" && <FaFileContract className="icon" size={size} color={color} />}
      {type === "mail" && <IoMail className="icon" size={size} color={color} />}
      {type === "connexion" && <TbPower className="icon" size={size} color={color} />}
      {type === "user_preference" && <RiUserSettingsLine className="icon" size={size} color={color} />}
      {type === "user_notification" && <BsBell className="icon" size={size} color={color} />}
      {type === "preference" && <VscSettings className="icon" size={size} color={color} />}
      {type === "users" && <FaUserFriends className="icon" size={size} color={color} />}
      {type === "company_create" && <MdAddCircle className="icon" size={size} color={color} />}
      {type === "company_informations" && <HiIdentification className="icon" size={size} color={color} />}
      {type === "company_employees" && <FaUserFriends className="icon" size={size} color={color} />}
      {type === "categories" && <AiFillTag className="icon" size={size} color={color} />}
      {type === "company" && <BsFillBuildingsFill className="icon" size={size} color={color} />}
      {type === "accountingFirm_informations" && <HiIdentification className="icon" size={size} color={color} />}
      {type === "accountingFirm_employees" && <FaUserFriends className="icon" size={size} color={color} />}
      {type === "suggest" && <AiFillTag className="icon" size={size} color={color} />}
      {type === "accountingFirm" && <HiBuildingOffice className="icon" size={size} color={color} />}
      {type === "importation" && <BsFileArrowDownFill className="icon" size={size} color={color} />}
      {type === "cycle" && <GrUpdate className="icon" size={size} color={color} />}
      {type === "send" && <IoIosSend className="icon" size={size} color={color} />}
      {type === "relance" && <LuAlarmClock className="icon" size={size} color={color} />}
      {type === "PES" && <MdQuestionMark className="icon" size={size} color={color} />}
      {type === "fec" && <BsFileArrowDownFill className="icon" size={size} color={color} />}
      {type === "quadra" && <Cegid size={size} />}
      {type === "tiime" && <Tiime size={size} />}
      {type === "acd" && <Acd size={size} />}
      {type === "connectors" && <BsPlugFill className="icon" size={size} color={color} />}
      {type === "technique" && <IoSettingsSharp className="icon" size={size} color={color} />}
      {type === "tarification" && <FaMoneyBillAlt className="icon" size={size} color={color} />}
      {type === "payment" && <FaFileInvoiceDollar className="icon" size={size} color={color} />}
      {type === "credit" && <FaFileInvoiceDollar className="icon" size={size} color={color} />}
      {type === "commercial" && <FaMoneyBillTransfer className="icon" size={size} color={color} />}
    </>
  );
}
