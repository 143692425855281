"use client";

import classNames from "classnames";
import { Tooltip } from "../../../components/infos";
import { AiFillDollarCircle } from "../../../react-icons/ai";
import { FaUserCog, FaUserGraduate, FaUserTag, FaUserTie } from "../../../react-icons/fa";

type Props = {
  size?: number;
  color?: string;
  value: string;
  selected?: string;
  setter?: (arg: string) => void;

};

const UserTeamIconItem = ({ size, color, value, setter = null, selected }: Props) => {

  return (
    <Tooltip
      legend={
        setter &&
        <>
          {value === "dollar" && "Dollar"}
          {value === "bug" && "Bug"}
          {value === "graduate" && "Stagiaires/apprentis"}
          {value === "admins" && "Dirigeants"}
          {value === "users" && "Utilisateur"}
        </>
      }
    >
      <div
        onClick={() => setter(value)}
        className={classNames({ "clickable tile gray-light m-0": setter })}
        style={{ ...(value == selected && { background: "var(--color-gray4)" }) }}
      >
        {value === "dollar" && <AiFillDollarCircle size={size} color={color} />}
        {value === "bug" && <FaUserCog size={size} color={color} />}
        {value === "graduate" && <FaUserGraduate size={size} color={color} />}
        {value === "admins" && <FaUserTie size={size} color={color} />}
        {value === "users" && <FaUserTag size={size} color={color} />}
      </div>
    </Tooltip>
  );
};

export default UserTeamIconItem;
