"use client";

import { usePathname } from "next/navigation";
import { BsFillMoonFill, BsFillSunFill } from "../../../react-icons/bs";

type Props = {
  isConnected: boolean;
  darkmode: boolean;
  activeDarkMode: () => void;
  disactiveDarkMode: () => void;
};

export function DarkModeBtn({ darkmode, activeDarkMode, disactiveDarkMode, isConnected }: Props) {
  const pathName = usePathname();

  return (
    <>
      {pathName && !pathName.includes("/dashboard") && !isConnected && (
        <>
          {darkmode ? (
            <button aria-label="dark mode" id="darkmode-btn" onClick={disactiveDarkMode}>
              <BsFillSunFill size={20} color="var(--color-text)" />
            </button>
          ) : (
            <button aria-label="dark mode" id="darkmode-btn" onClick={activeDarkMode}>
              <BsFillMoonFill size={20} color="var(--color-text)" />
            </button>
          )}
        </>
      )}
    </>
  );
}
