import { useEffect, useState } from "react";
import { CompanyConnectorPairingKeyType } from "../../redux/types";
import { AccountingFirmConnectorType } from "./../../redux/types";

export function useExerciceConnectorAppaired(connectors: AccountingFirmConnectorType[], pairingKey?: CompanyConnectorPairingKeyType): AccountingFirmConnectorType[] {
  const [filteredConnectors, setFilteredConnectors] = useState<AccountingFirmConnectorType[]>([]);

  useEffect(() => {
    if (pairingKey) {
      let temp = JSON.parse(JSON.stringify(connectors));
      let connectorsPaired = Object.entries(pairingKey)
        .filter((item) => item[1] && item[0] != "id")
        .map((item) => item[0]);

      temp = temp.filter((connector: AccountingFirmConnectorType) => {
        if (
          connector?.accountingFirmConnectorType?.isApi == true &&
          connector?.accountingFirmConnectorType?.value &&
          connectorsPaired.includes(connector?.accountingFirmConnectorType?.value)
        ) {
          return connector;
        }
      });

      setFilteredConnectors(temp);
    }
  }, [pairingKey, connectors]);

  return filteredConnectors;
}
