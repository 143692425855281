"use client";
import { HTMLAttributes, useEffect, useRef, type JSX } from "react";
import { ModalDesktop } from "./ModalDesktop";

interface Props extends HTMLAttributes<HTMLDialogElement> {
  setShowModal?: (arg: boolean) => void;
  showModal: boolean;
  id?: string;
  className?: string;
  width?: string;
  height?: string;
  translateX?: string;
  translateY?: string;
  maxWidth?: React.CSSProperties["maxWidth"];
  maxHeight?: React.CSSProperties["maxHeight"];
  bodyStyle?: React.CSSProperties;
};

export function Modal({
  children,
  showModal,
  height = "fit-content",
  ...props
}: Props): JSX.Element {
  const ref = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {

    if (ref?.current) {
      if (showModal) {
        ref.current.showModal();
      } else {
        ref.current?.close();
      }
    }

  }, [ref?.current, showModal]);

  return (
    <>
      {showModal &&
        <ModalDesktop
          height={height}
          ref={ref}
          {...props}
        >
          {children}
        </ModalDesktop>}
    </>
  );
}
