import classNames from "classnames";
import { CssVariablesType } from "../../styles/CSSVariablesTypes";

import type { HTMLAttributes, JSX } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
  form?: "circle" | "square" | "rectangle";
  color?: CssVariablesType;
  paddingSize?: number;
  size?: number | undefined;
  className?: string;
  id?: string;
  onClick?: any;
  style?: React.CSSProperties;
}

export function Shape({ form = "circle", className, id, children, color, size, style, paddingSize = size ? size / 10 : 10, onClick, ...props }: Props) {
  return (
    <div
      id={id}
      onClick={() => {
        onClick && onClick();
      }}
      className={classNames("shape", form, className)}
      style={{
        background: color && `var(--color-${color})`,
        height: size ? size + "px" : "max-content",
        width: size ? size + "px" : "auto",
        aspectRatio: form === "circle" || form === "square" ? "1/1" : "3/2",
        padding: paddingSize + "px",
        borderRadius: form === "circle" ? "50%" : form === "square" ? "20%" : "var(--size-borderRadius)",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
}
